
import { Action } from '@ngrx/store';
import { SearchResultsResponse } from './search-results.model';

// Define action types
export enum SearchResultsActionTypes {
  LoadSearchResults = '[Search Results] Load Search Results',
  LoadSearchResultsSuccess = '[Search Results] Load Search Results Success',
  LoadSearchResultsFailure = '[Search Results] Load Search Results Failure',
}

 export interface SearchResultsPayload {
  query: string;
  context: string;
  productNumber?: string;
}

// Define action creators
export class LoadSearchResults implements Action {
  readonly type = SearchResultsActionTypes.LoadSearchResults;
  constructor(public payload: SearchResultsPayload) {}
}

export class LoadSearchResultsSuccess implements Action {
  readonly type = SearchResultsActionTypes.LoadSearchResultsSuccess;
  constructor(public payload: SearchResultsPayload,public response: SearchResultsResponse) {}
}

export class LoadSearchResultsFailure implements Action {
  readonly type = SearchResultsActionTypes.LoadSearchResultsFailure;
  constructor(public error: any) {}
}

// Export all actions as a union type
export type SearchResultsActions = LoadSearchResults | LoadSearchResultsSuccess | LoadSearchResultsFailure;
