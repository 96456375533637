import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, first } from 'rxjs/operators';
import { PageService } from '../../../services/common_service/page.service';
import { cclcAlias } from '../../../pages/contact/configuration/cclc-aliases.config';
import { LoadClcRegions } from "../../../store/regions/regions.actions";
import { getAllCLCRegions } from "../../../store/regions/regions.selectors";
import { AppState } from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { isEmpty } from 'lodash-es';
import { CommonService } from "../../../services/common_service/common.service";

// Already provided in Component
@Injectable()

export class CountryLocatorService {
  pageConfig: any = this.pageService.config;

  allCcLcAlias = cclcAlias;

  constructor(
    private pageService: PageService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) {}

  getUserCountry(userData, isWebCompActive=false, wcCC=null, wcLC=null) {
    let userCountry;
    let ccLcAlias;
    const currentCC = isWebCompActive ? wcCC : this.pageConfig.cc;
    const currentLC = isWebCompActive ? wcLC : this.pageConfig.lc;

    userData.forEach(data => {
      if (data && data.country) {
        data.country.forEach(country => {
          if(currentCC==='ua' && currentLC==='uk'){
            if ((country.countryCode).toLowerCase() === currentCC && (country.languageCode).toLowerCase() === 'ru') {
              userCountry = country;
              this.pageService.setCurrentCountry(userCountry);
            } 
          }

          if (this.pageConfig.pageName === 'contact') {
            ccLcAlias = this.allCcLcAlias[`${currentCC}-${currentLC}`];

            if (ccLcAlias &&
              (ccLcAlias['actualCc'].toLowerCase() === country?.countryCode?.toLowerCase() && ccLcAlias['actualLc'].toLowerCase() === country?.languageCode?.toLowerCase())) {
              userCountry = country;
              this.pageService.setCurrentCountry(userCountry);
            }
          }

          if (!userCountry) {
            if ((country.countryCode).toLowerCase() === currentCC && (country.languageCode).toLowerCase() === currentLC) {
              userCountry = country;
              this.pageService.setCurrentCountry(userCountry);
            } 
          }
        });
      }
    });
    return userCountry;
  }

 getData(isWebCompActive=false, wcApiDomain=null, wcCC=null,  wcLC=null) {

  let allRegionsUrl;
    if(isWebCompActive ){ 
         allRegionsUrl =  `${wcApiDomain}/wcc-services/clc/allRegions`;
    }
    else {
      if(this.pageConfig?.isServicePage) {
        
          const oid = this.route.children[0]?.snapshot?.params.seriesId;
          allRegionsUrl = `/wcc-services/clc/allRegions?query=${oid}`;
      }
      else {
           allRegionsUrl =  '/wcc-services/clc/allRegions';
      }
    }

    this.store.dispatch(new LoadClcRegions(allRegionsUrl));

    return this.store.select(getAllCLCRegions)
      .pipe(
        first( regions => !isEmpty(regions)),
        map(regions => {
            const userData = regions;
            const userCountryData = this.getUserCountry(userData, isWebCompActive, wcCC, wcLC);            
            if (this.pageConfig?.isServicePage) {
              this.getRedirectedToSupportedCountry(regions);
            }
            return { userData, userCountryData }
        })
      );
  }

  getRedirectedToSupportedCountry(regions) {
    const modifiedlList = [];
    regions.forEach((a) => {
      a.country.forEach((k) => {
        let cclc = `${k.countryCode}-${k.languageCode}`;
        modifiedlList.push(cclc);
      });
    });

    const isCountryPresent = modifiedlList.filter((o) => {
      return o === `${this.pageConfig.cc}-${this.pageConfig.lc}`;
    });
    if (isCountryPresent.length <= 0) {
      if (typeof window !== 'undefined') {
        const url = new URL(location.href);
        const params = new URLSearchParams(url.search);

        params.append('openCLC', 'true');

        const sdpUrl = window.location.href.split("/service")[1];
        const resolvedUrl = `us-en/service${sdpUrl}?openCLC=true`;

        if (!this.pageConfig.noCCLCAllowed) {
          window.location.href = this.commonService.sanitizeUntrustedData(resolvedUrl);
        }
      }
    }
  }
}