import { Inject, Injectable } from "@angular/core";
import { AppState } from "./app.reducer";
import { Store } from "@ngrx/store";
import { ConfigKeysModel } from "../services/ssc-store/sscKeys.reducer";
import { catchError, filter, first, map, mergeMap, tap } from "rxjs/operators";
import { isEmpty } from "lodash-es";
import { BehaviorSubject, Observable, of } from "rxjs";
import { StartFetchingSscKeysAction } from "../services/ssc-store/sscKeys.actions";
import { ProductSpecs, Warranty } from "../model/warranty-spec.model";
import { StartFetchingTermbaseAction } from "./termbase-store/termbase.actions";
import { TermbaseKeysModel } from "./termbase-store/termbase.reducer";
import { ProductWarrantySelector, selectProductSpecs } from "../pages/pdp/store/pdp/pdp.selectors";
import { PageService } from "../services/common_service/page.service";
import { pageTermbaseSelector } from "./termbase-store/termbase.selector";
import { sscKeysSelector, udlKeysSelector } from "../services/ssc-store/config.selectors";
import { CountryList } from "../modules/feature-module/country-lang-selector/countryList.model";
import { getCountryListSelector } from "./translated-countrylist/translated-countrylist.selector";
import { CountryListState } from "./translated-countrylist/translated-countrylist.reducer";
import { CommonService } from "../services/common_service/common.service";
import { StoreCountryListAction } from "./translated-countrylist/translated-countrylist.actions";
import { DOCUMENT } from "@angular/common";


@Injectable({
    providedIn: 'root'
})
export class AppSelectorStore {
    
    private pageConfig: any = this.pageService.config;

    private window:Window;

    isVisIdEnabledInfinite$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private store:Store<AppState>,
        private pageService: PageService,
        private commonService: CommonService,
        @Inject(DOCUMENT) private document: Document
    ){
        this.window = this.document.defaultView;

        this.isVisIdEnabled(false).subscribe(res=>{
            this.isVisIdEnabledInfinite$.next(res);
            this.pageConfig.isVisIdEnabled = res;
        });
    }

    getSscKeys$(onlyFirst: boolean = true): Observable<ConfigKeysModel> {
        const ob$: Observable<ConfigKeysModel> = this.store.select(sscKeysSelector)
            .pipe(
                filter((sscKeys: ConfigKeysModel) => sscKeys && !isEmpty(sscKeys))
            )
        if(onlyFirst) {
            return ob$.pipe(first())
        }
        return ob$;
    }

    getSscKey$(keyArray: string[], onlyFirst: boolean = true): Observable<{ [key: string]: string }> {
        const ob$: Observable<{ [key: string]: string }> = this.getSscKeys$(onlyFirst).pipe(
            map((sscKeys: ConfigKeysModel): { [key: string]: string } => {
                const result: { [key: string]: string } = {};
                for (const config of keyArray) {
                    if (sscKeys[config]) {
                        result[config] = sscKeys[config].keyValue;
                    } else {
                        result[config] = null;
                    }
                }
                return result;
            })
        );
        return ob$;
    }

    getUdlKeys$(onlyFirst: boolean = true): Observable<ConfigKeysModel> {
        const ob$: Observable<ConfigKeysModel> = this.store.select(udlKeysSelector)
            .pipe(
                filter ((udlKeys: ConfigKeysModel) => udlKeys && !isEmpty(udlKeys))
            )
        if (onlyFirst) {
            return ob$.pipe(first())
        }
        return ob$;
    }

    getWarrantyInfoSpecApi$(): Observable<Warranty> {
        return this.store.select<Warranty>(ProductWarrantySelector)
            .pipe(
                filter(warranty => warranty && !isEmpty(warranty))
            );
    }

    getProductInfoSpecApi$(): Observable<ProductSpecs> {
        return this.store
            .select<ProductSpecs>(selectProductSpecs)
            .pipe(
                filter(productSpecs => productSpecs && !isEmpty(productSpecs))
            );
    }

    fetchSscAction(payload?: {
        pageName?: string,
        isWebCompActive?: boolean,
        wcCC?: string,
        wcLC?: string,
        wcApiDomain?: string
    }) {
        this.store.dispatch(new StartFetchingSscKeysAction(payload));
    }

    fetchTranslations(pageName?: string) {
        this.store.dispatch(new StartFetchingTermbaseAction({
            pageName: pageName
        }))
    }

    getAllTranslations$(onlyFirst:boolean=true):Observable<TermbaseKeysModel>{
        let ob$:Observable<TermbaseKeysModel> = this.store.select(pageTermbaseSelector).pipe(filter(keys => keys && !isEmpty(keys)));
        if(onlyFirst) ob$ = ob$.pipe(first());
        return ob$;
    }

    isVisIdEnabled(onlyFirst:boolean=true):Observable<boolean>{
        /*
        
            Commented for future usage, additional service OR check for temporary usage
            Will use commented code after entire service page migrated to visId

        */
        // return this.getSscKey$(['ENABLE_VIS_ID_LAYOUT'], onlyFirst).pipe(
        //     map(res=>{
        //         try{
        //             return res?.ENABLE_VIS_ID_LAYOUT && res?.ENABLE_VIS_ID_LAYOUT?.includes(this.pageService.config.pageName);
        //         }catch(e){
        //             return false;
        //         }
        //     })
        // );

        return this.getSscKey$(['ENABLE_VIS_ID_LAYOUT', 'SDP_HPONE_PCAAS_PAAS_SERIESOID'], onlyFirst).pipe(
            map(res=>{
                try{
                    if(res?.SDP_HPONE_PCAAS_PAAS_SERIESOID){
                        const seriesId = this.window.location.pathname.split('/')[4];
                        return res?.SDP_HPONE_PCAAS_PAAS_SERIESOID?.includes(seriesId);
                    }
                    else {
                        if (this.pageService.config.pageName === 'securityBulletin') {
                          return res?.ENABLE_VIS_ID_LAYOUT?.split(',')
                            .map(page => page.trim())
                            .some(page => page === this.pageService.config.pageName);
                        }
                        return res?.ENABLE_VIS_ID_LAYOUT && res?.ENABLE_VIS_ID_LAYOUT?.includes(this.pageService.config.pageName);
                       }
                }catch(e){
                    return false;
                }
            })
        );
    }

    isVideoAutoPlayEnabled():Observable<boolean>{
        return this.getSscKey$(['MODAL_VIDEO_AUTOPLAY_CONTROL']).pipe(
            map(res=>res?.MODAL_VIDEO_AUTOPLAY_CONTROL !== "false")
        );
    }

    getTranslatedCountryList(skipLoadingScreen:boolean=false): Observable<CountryList[]>{
        return this.store.select<CountryListState>(getCountryListSelector).pipe(
            first(),
            mergeMap((state:CountryListState): Observable<CountryList[]> => {
                if(state.success && state.response && state.response[this.pageConfig.locale || 'us-en']){
                    return of(state.response[this.pageConfig.locale || 'us-en']);
                }else{
                    return this.commonService.getTranslatedCountryListData(skipLoadingScreen).pipe(
                        tap(resp=>{
                            this.store.dispatch(new StoreCountryListAction({
                                success: true,
                                locale: this.pageConfig.locale || 'us-en',
                                response: resp
                            }));
                        }
                    ))
                }
            }),
            catchError(error=>{
                return of(null);
            })
        );
    }
}