import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingScreenService } from "./loader.service";
import { CommonService } from "../common_service/common.service";
import { GlobalDataLayerService } from '../../services/common_service/globalDataLayer.service';
import { PageService } from "../common_service/page.service";

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

  // Removed Variable to fix loading issue, moved to LoadingScreenService
  // activeRequests: number = 0;

  constructor(
        private loadingScreenService: LoadingScreenService,
        private commonService: CommonService,
        private globalDataService: GlobalDataLayerService,
        private pageService: PageService
      ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;
    // console.log('loading.interceptor.js - intercept - Request received', request);

    let relayedRequest;
    // console.log('loading.interceptor.js - intercept - Header params', request.headers.get('skipLoadingScreen'));
    if(request.headers.get('skipLoadingScreen')){
        relayedRequest = request.clone({headers: request.headers.delete('skipLoadingScreen')});
        displayLoadingScreen = false;
    }
    else{
      relayedRequest = request;
    }
    if(request && request.url){
      let displayKeyParam;
      if((request.url.indexOf('cms-') != -1) && (this.commonService.displayKeyValue) && (this.commonService.displayKeyValue.displayKey)){
        
        if(request.url.indexOf('?') != -1){
          displayKeyParam = relayedRequest.url + '&displayKey=true';
        }
        else{
          displayKeyParam = relayedRequest.url + '?displayKey=true';
        }
        relayedRequest = request.clone({url: displayKeyParam});
      }
      if((request.url.indexOf('wcc-services') != -1)){
        //Added to pass usersatte and page template (GDL) parameter to API 
        const globalDataLayer =this.globalDataService.getLocalstorageGlobalData()
        let authState = globalDataLayer?.customerState?.toLowerCase()!== 'anonymous'? 'signedin' : 'anonymous';
        const debugMode = this.pageService?.config?.queryParams?.['render_mode'] === 'debug';
          if(relayedRequest.url.indexOf('?') != -1){
            displayKeyParam = relayedRequest.url + `&authState=${authState}&template=${globalDataLayer?.Template || 'null'}`;
          }
          else{
            displayKeyParam = relayedRequest.url + `?authState=${authState}&template=${globalDataLayer?.Template || 'null'}`;
          }
          displayKeyParam = debugMode ? displayKeyParam + '&render_mode=debug' : displayKeyParam;
          relayedRequest = request.clone({url: displayKeyParam});
        }
    }
    if (displayLoadingScreen) {
      if (this.loadingScreenService.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.loadingScreenService.activeRequests++;

      return next.handle(relayedRequest).pipe(
        finalize(() => {
          this.loadingScreenService.activeRequests--;
          if (this.loadingScreenService.activeRequests === 0 && !this.loadingScreenService.blockScreen) {
            this.loadingScreenService.stopLoading();
          }
        })
      )
    } else {
      return next.handle(relayedRequest);
    }
  };
}
