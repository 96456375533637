import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpService } from '../../../../services/service_loader/http.service';
import { PageService } from '../../../../services/common_service/page.service';
import { CommonService } from '../../../../services/common_service/common.service';
import { AppSelectorStore } from '../../../../store/app.selector';
import { TermbaseKeysModel } from '../../../../store/termbase-store/termbase.reducer';
import { OfferService } from '../../../../services/mastiff-services/offer.service';
import { MastiffOffersState, OfferEntity } from '../../../../store/mastiff-offers-store/mastiff-offers.reducer';
import { GlobalDataLayerService } from '../../../../services/common_service/globalDataLayer.service';
import { MASTIFF_PAGE_IDS } from '../../../../constants/app-constants';
@Component({
  selector: 'app-retired-product',
  templateUrl: './retired-product.component.html',
  styleUrls: ['./retired-product.component.scss'],
  providers: [NgbModal],
})

export class RetiredProductComponent implements OnInit, OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  // Translations
  allTranslations:TermbaseKeysModel = {};

  // Feature related config
  @Input() retiredProductData;
  retiredproductResourceLinks;

  // Resolution based display
  largeDevice;
  smallDevice;

  visidEnabled:boolean = false;

  @Input() hideTile;
  @Input() openOnLoad;

  @Output() modalClosed = new EventEmitter<any>()
  @Input() historicalProductData;
  @ViewChild('content', { static: true }) clcModalRef: ElementRef;
  mastiffShopLink: any;
  private destroySubject$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private pageService: PageService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private http: HttpService,
    private appSelectorStore:AppSelectorStore,
    private offerService : OfferService,
    private globalDataService: GlobalDataLayerService
  ) {}

  ngOnDestroy(): void {
    if (typeof sessionStorage !== 'undefined') sessionStorage?.removeItem('historicalProductData');
    this.destroySubject$.next(true);
  }

  closeErrorModal() {
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 250);
  }

  resolutionBaseDisplay() {
    const getResolutionValue = this.commonService.resolutionBaseDisplay();
    this.largeDevice = getResolutionValue._largedevice;
    this.smallDevice = getResolutionValue._smallDevice;
  }

  ngOnInit() {
    this.globalDataService.updateGDL({
      Product: {
        seriesOid: this.historicalProductData?.productSeriesOID ?? '',
        bigSeriesOid: this.historicalProductData?.productBigSeriesOid ?? '',
        productNumberOid: this.historicalProductData?.productNumberOid ?? '',
        productName: this.historicalProductData?.productName ?? '',
        productSeriesName: this.historicalProductData?.productSeriesName ?? '',
        productNumber: this.historicalProductData?.productNumber ?? '',
        productLineCode: this.historicalProductData?.productLineCode ?? '',
        productAudience: this.historicalProductData?.productAudience ?? '',
        platformId: this.historicalProductData?.productPlatform ?? '',
        serialNumber: this.historicalProductData?.serialNumber ?? '',
        historicalWebSupportFlag: true
      },
      Template: 'retiredproductmodal'
    });

    this.getMastiffOfferLink()
    
    this.appSelectorStore.getAllTranslations$().pipe(takeUntil(this.destroySubject$)).subscribe(res=>this.allTranslations=res);

    this.resolutionBaseDisplay();
   
  }

  get mastiffLinkId() {
    return this.mastiffShopLink?.primaryButtonText?.split(' ')?.join('_').toLowerCase() || '';
  }    

  fetchAemOffers() {
    this.http
      .get(`wcc-services/cms-v2/${this.pageConfig.cc}-${this.pageConfig.lc}/wcc_dashboard_modal_retired`)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        if (res && res.data) {
          this.retiredproductResourceLinks = res.data;
        }
      });
  }
 
  getMastiffOfferLink() {  
    const products =[
      {
        modelNumber: this.historicalProductData?.altProductNumber ||  this.historicalProductData?.productNumber,
        serialNumber:  this.historicalProductData?.serialNumber,
        productSeriesOid:  this.historicalProductData?.productSeriesOID?.toString(),
        productNameOid:  this.historicalProductData?.productNameOID?.toString(),
        warranty: {
        }
      }

    ];

    if (products) {
      this.offerService.getMastiffOffers(MASTIFF_PAGE_IDS?.RETIREDPRODUCTMODAL, products)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((mastiffOffersState: MastiffOffersState) => {
          if (typeof sessionStorage !== 'undefined') sessionStorage?.removeItem('historicalProductData');
          if (!mastiffOffersState?.loading && mastiffOffersState?.offers?.length) {
            this.mastiffShopLink = mastiffOffersState.offers[0];
          } else if (!mastiffOffersState?.loading) {
            // fallback to AEM if mastiff offers is unavailable
            this.fetchAemOffers();
          };
        })
    }
  }
}
