import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import {  Observable, of, Subject } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";
import { Device } from "../../../app/model/warranty-spec.model";
import { MastiffOffersPayload, Product } from "../../../app/pages/pdp/model/pdp.models";
import { OfferConstants } from './offer.constant';
import { GetMastiffOffers, StoreMastiffOffers } from "../../store/mastiff-offers-store/mastiff-offers.actions";
import { MastiffOffersStore, SpecsDataFirstDeviceSelector, SearchResultSelector } from "../../store/mastiff-offers-store/mastiff-offers-selectors";
import { AppState } from "../../../app/store/app.reducer";
import { MastiffOffersResponse, MastiffOffersState, OfferEntity } from '../../../app/store/mastiff-offers-store/mastiff-offers.reducer';
import { isEqual } from 'lodash-es';
import { HttpClient } from "@angular/common/http";
import { PageService } from "../common_service/page.service";

@Injectable({
    providedIn: 'root'
  })
  
export class OfferService implements OnDestroy {

  destroySubject$: Subject<void> = new Subject();
  sscKeys: any;
  pdpService: any;
  pageConfig = this.pageService.config;

    constructor(
      private store: Store<AppState>,
      private http: HttpClient,
      private pageService: PageService
    ) { }

    fetchSearchResults(payload: MastiffOffersPayload): Observable<MastiffOffersState> {
      const pageId = payload?.pageId || '';

      const url = `/wcc-services/pdp/${this.pageConfig.locale}/offers?pageId=${pageId}`
      return this.http.post(url, payload)
        .pipe(
          map((response: MastiffOffersResponse) => {
            delete payload?.requestId;
            if (response?.code === 200 && response?.data?.length) {
                 this.store.dispatch(StoreMastiffOffers({offers: response?.data, payload} ));
                } else {
                  this.store.dispatch(StoreMastiffOffers({offers: [], payload }));
                }
                return {
                  loading: false,
                  success: true,
                  offers: response?.data,
                  payload
                };
              }),
          catchError(error => {
            delete payload?.requestId;
            this.store.dispatch(StoreMastiffOffers( { offers: [], payload }));
            return of(error);
          })
        )
  }

    //TODO Need to Refactor
  getMastiffOffers(pageId: string, products:Product[]) : Observable<MastiffOffersState> {
      const payload: MastiffOffersPayload = {
        pageId,
        products
      }
      return this.store.select(MastiffOffersStore)
      .pipe(
          first(),
          switchMap((state: MastiffOffersState) => {
              if (isEqual(state?.payload, payload) && state?.offers?.length) {
                  return of(state);
              } else {
                const REQUESTID_CHARACTERS = this.sscKeys?.PDP_MASTIFF_REQUESTID_CHARACTERS?.keyValue || OfferConstants.MASTIFF_REQUESTID_CHARACTERS;
                const requestId = this.createReqId(REQUESTID_CHARACTERS);
                payload.requestId = requestId;
                return this.fetchSearchResults(payload);
              }
          })
      )
  }

 


    // getMastiffOffers1(pageId : string){
    //   return this.store.select(SpecsDataFirstDeviceSelector)
    //   .pipe(switchMap((specsData: Device) => {
    //     const REQUESTID_CHARACTERS = this.sscKeys?.PDP_MASTIFF_REQUESTID_CHARACTERS?.keyValue || OfferConstants.MASTIFF_REQUESTID_CHARACTERS;
    //     const payload: MastiffOffersPayload = {
    //       requestId: this.createReqId(REQUESTID_CHARACTERS),
    //       pageId: pageId,
    //       products: [
    //         {
    //           modelNumber: specsData?.warranty?.altProductNumber || specsData?.productSpecs?.productNumber,
    //           serialNumber: specsData?.productSpecs?.serialNumber,
    //           productSeriesOid: specsData?.productSpecs?.productSeriesOid?.toString(),
    //           productNameOid: specsData?.productSpecs?.productNameOid?.toString(),
    //           warranty: {
    //           }
    //         }
    //       ],
    //     }
    //     this.store.dispatch(GetMastiffOffers({ payload }));
    //     return this.store.select(MastiffOffersStore);
    //   }))
    // }

    // This needs to be called when search result is available
 

   
    // getMastiffShoppingLink(pageId : string,products?){
    //  const REQUESTID_CHARACTERS = this.sscKeys?.PDP_MASTIFF_REQUESTID_CHARACTERS?.keyValue || OfferConstants.MASTIFF_REQUESTID_CHARACTERS;
    //   const payload: MastiffOffersPayload = {
    //       requestId: this.createReqId(REQUESTID_CHARACTERS),
    //       pageId: pageId,
    //       products: products
    //     }
    //   this.store.dispatch(GetMastiffOffers({ payload }));
    //   return this.store.select(MastiffOffersStore);
    // }

    createReqId(REQUESTID_CHARACTERS) {
      const timestamp = Date.now();
      const tsLength = String(timestamp).length;
      let randStrLen = 20 - tsLength;
      const characters = REQUESTID_CHARACTERS;
      const charactersLength = characters?.length;
      let result = "";
      for (let i = 0; i < randStrLen; i++) {
        result += characters?.charAt(Math.floor(Math.random() * charactersLength));
      }
      return String(timestamp).concat(result);
    }

    filterAndSortOffers(data: OfferEntity[], categoryCheck: boolean = true): OfferEntity[] {
      return data
        .filter(offer =>
          (offer.offerTitle && offer.offerTitle !== 'null') &&
          (categoryCheck ? (offer.category && offer.category !== 'null') : true) &&
          (offer.productSKU && offer.productSKU !== 'null')
        )
        .sort((a, b) => +a.offerRank - +b.offerRank);
    }

    ngOnDestroy() {
        this.destroySubject$.next();
      }
  }