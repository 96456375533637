import { Component, OnInit,  Inject, OnDestroy,Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScriptLoaderService } from '../../services/script_loader/scriptLoader.service';
import { AuthService } from '../../services/common_service/auth.service';
import { ActivatedRoute, NavigationEnd, Router, ActivatedRouteSnapshot, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { PageService } from '../../services/common_service/page.service';
import { GlobalDataLayerService } from '../../services/common_service/globalDataLayer.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SSCStore } from '../../store/ssc.store';
import { AppState } from '../../store/app.reducer';
import { ThemeLoaderService } from '../../services/theme-loader/theme-loader.service';

import * as ProductContextActions from '../../store/product-context/productContext.actions';

import { Store } from '@ngrx/store';
import { isEmpty } from "lodash-es";
import { Meta } from "@angular/platform-browser";
import { regional2ByteCCCodes } from "../../config/regional-two-byte-cc-code-config/regional-two-byte-cc-code.config"
import { UdlLogService } from '../../services/common_service/udl.service';
import { AppSelectorStore } from "../../store/app.selector";
import { LaunchDarklyService } from '../../services/common_service/launchDarkly.service';
import { LoadingScreenService } from '../../services/loader/loader.service';
declare global {
  interface Window { QSI: any; }
}
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})


export class AppLayoutComponent implements OnInit, OnDestroy {
  pageConfig: any = this.pageService.config;
  initialized: boolean;
  globalData;
  authObject;
  isUDLSwitchOn;
  isUDLEnabledTemplate;
  loadAPI: Promise<any>;
  isUserAuthenticated;
  countryCode;
  pageName;
  // pagelevel related config
  SiteHome_New = {
    "L5": "support-home",
    "L6": "",
    "L8": "support-home"
  };
  regionalTwobyteCodes = regional2ByteCCCodes;

  externalScriptFiles = [
    '/wcc-assets/content/dam/hp-wcc/libs/scripts/third_party_lib.js',
    '/wcc-assets/content/dam/hp-wcc/libs/scripts/medallia.js',
    '/wcc-assets/content/dam/hp-wcc/libs/scripts/CS_analytics_mask.js'
  ];
  private destroySubject$: Subject<void> = new Subject();

  private window: Window;

  constructor(
    private scriptLoader: ScriptLoaderService,
    private sscStore: SSCStore,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PageService,
    private globalDataLayerService: GlobalDataLayerService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<AppState>,
    private meta: Meta,
    private udlLogService: UdlLogService,
    private appSelectorStore: AppSelectorStore,
    private launchDarklyService : LaunchDarklyService,
    private loadingScreenService: LoadingScreenService,
    private themeLoaderService: ThemeLoaderService,
    private renderer2: Renderer2
  ) {
    this.window = this.document.defaultView;
    
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        if (!this.initialized && typeof sessionStorage !== 'undefined') {
          sessionStorage?.removeItem('contactOriginId');
        }

        const routeData = route.data;
        const routeParams = route.params;
        const queryParams = route.queryParams;
        const makeSSOCall = (routeData.skipSSOCheck && !this.initialized) || !routeData.skipSSOCheck;
        // const gdl = this.globalDataLayerService.getLocalstorageGlobalData();
        const isServicePage = this.router.url.indexOf("/service/") !== -1 ? true : false;

        this.pageService.init({ routeData, routeParams, queryParams, isServicePage });

        if (!this.initialized && this.pageConfig.pageName === 'contact' && this.pageConfig.queryParams.originid) {
          sessionStorage?.setItem('addOriginIdSuffixToTemplateName', 'true');
        } else if((!this.initialized && this.pageConfig.pageName !== 'contact') || (this.initialized && this.pageConfig.pageName !== 'contact')) {
          sessionStorage?.removeItem('addOriginIdSuffixToTemplateName');
        }

        const gdlObj = {
          CountryCode: this.pageConfig.cc,
          LanguageCode: this.pageConfig.lc,
          Locale: this.pageConfig.locale
        };

        if (this.pageConfig?.templateName && !isServicePage) {
          gdlObj['Template'] = this.pageConfig.templateName;
        }

        this.globalDataLayerService.updateGDL(gdlObj);

        if (queryParams?.originid === 'hpone') {
          this.router.navigate([], {
            queryParams: {
              originid: null
            },
            queryParamsHandling: 'merge'
          })
        }
        //To fix SSVSPRT-14487
        if(typeof window != 'undefined' && this.window.QSI){
          this.window.QSI.API.unload();
          this.window.QSI.API.load();
        }

        this.pageName = routeData.pageName;
        // removing sessionData when swd drivers results page navigates to other pages
        if(this.pageName !== 'swdDrivers' && this.pageName !== 'swdClosure' && typeof sessionStorage !== 'undefined') {
          sessionStorage?.removeItem('swdOsSniffedData');
        }

        if (makeSSOCall && !isServicePage && !this.initialized) {
          this.sscStore.getHpDotComCcLcMapping();
        }

        this.store.dispatch(ProductContextActions.StoreProductContext({ productContext: {
          seoName: routeParams?.seoName || routeParams.seriesName,
          seriesOid: routeParams?.seriesOid || routeParams.seriesOID,
          modelOid: routeParams?.modelOid || routeParams.modelOID,
          serialNumber: queryParams?.serialnumber,
          sku: queryParams?.sku
        }}));

        if (makeSSOCall) {
          this.authService.getAuthObject();
        } else {
          this.authService.signinExp();
        }
        
      });

    this.loadAPI = new Promise((resolve) => {
      this.scriptLoader.loadScript(this.externalScriptFiles);
      resolve(true);
    });
  }

  getRegional2ByteCCcodes() {
    if (this.regionalTwobyteCodes.hasOwnProperty(this.pageConfig.cc)) {
      this.countryCode = this.regionalTwobyteCodes[`${this.pageConfig.cc}`]['twoByteCC'];
      this.document.documentElement.lang = `${this.pageConfig.lc}-${this.countryCode}`;
      this.meta.updateTag({ name: 'target_country', content: `${this.countryCode}` });
    } else {
      this.document.documentElement.lang = `${this.pageConfig.lc}-${this.pageConfig.cc.toUpperCase()}`;
      this.meta.updateTag({ name: 'target_country', content: `${this.pageConfig.cc}` });
    }
  }

  ngOnInit() {
    this.loaderForLazyLoading();
    this.getRegional2ByteCCcodes();
    this.udlLogService.initiateUdlScript();
    this.authService.userDataSub
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(
        async (res) => {
          if (!isEmpty(res)) {
            this.pageConfig.authData = res;
            this.initialized = true;
            this.isUserAuthenticated = this.pageConfig.authData && this.pageConfig.authData.customerId && this.pageConfig.authData.customerId.length;
            if (this.isUserAuthenticated) {
              this.udlLogService.logLoginStatus(true);
            }
          }

          // Fetch all feature flags
          if (!this.isUserAuthenticated) {
            // If user is not logged in, pass name as Anonymous
            await this.launchDarklyService.initializeFlags("Anonymous");
          }
          else {
            // If user is logged in, pass name as customerId
            await this.launchDarklyService.initializeFlags(this.pageConfig.authData.customerId);
          }
        }
      );

    this.appSelectorStore.getSscKey$(['ENABLE_VENEER_LAYOUT', 'ENABLE_VIS_ID_LAYOUT', 'SDP_HPONE_PCAAS_PAAS_SERIESOID'], false).subscribe(resp=>{

      try{
        const {ENABLE_VENEER_LAYOUT, SDP_HPONE_PCAAS_PAAS_SERIESOID, ENABLE_VIS_ID_LAYOUT} = resp;
        let seriesId;
        if(typeof this.window !== 'undefined'){
           seriesId = this.window.location.pathname.split('/')[4];
        }
        
        if(SDP_HPONE_PCAAS_PAAS_SERIESOID && SDP_HPONE_PCAAS_PAAS_SERIESOID !== null && seriesId && SDP_HPONE_PCAAS_PAAS_SERIESOID?.includes(seriesId)){
          this.themeLoaderService.setTheme('visid', this.renderer2);
        }else{
          if (ENABLE_VENEER_LAYOUT && ENABLE_VENEER_LAYOUT.includes(this.pageConfig.pageName)) {
            this.themeLoaderService.setTheme('veneer', this.renderer2);
           } else  if (ENABLE_VIS_ID_LAYOUT) {
            if (this.pageConfig.pageName === 'securityBulletin') {
                // Check for exact match
                if (ENABLE_VIS_ID_LAYOUT?.split(',').some(page => page.trim() === this.pageConfig.pageName)) {
                    this.themeLoaderService.setTheme('visid', this.renderer2);
                } else {
                  throw 0;
                }
            } else if (ENABLE_VIS_ID_LAYOUT?.includes(this.pageConfig.pageName)) {
                // For other page names, just check includes
                this.themeLoaderService.setTheme('visid', this.renderer2);
            } else {
              throw 0;
            }
         } else {
            throw 0;
          }
        }
      }catch(e){
        // loading default theme
       this.themeLoaderService.setTheme('default', this.renderer2);
      }

    });
  }

  loaderForLazyLoading() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingScreenService.startLoading(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingScreenService.stopLoading(true);
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
