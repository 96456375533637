import { SscKeysEffects } from "../services/ssc-store/sscKeys.effects";
import { AlertsEffects } from "./alerts-store/alerts.effects";
import { AllDeviceEffects } from "./all-devices-store/all-devices.effects";
import { ConfirmDeviceEffects } from "./confirm-device-store/confirm-device.effects";
import { SpecsEffects } from "./specs-store/specs.effects";
import { Device_DeviceIdEffects } from "./device-deviceId-store/device-deviceId.effects";
import { DeviceWarrantyEffects } from "./device-warranty-store/device-warranty.effects";
import { RegionsEffects } from "./regions/regions.effect";
import { SyncStatusEffects } from "./sync-status-store/sync-status.effects";
import { TermbaseEffects } from "./termbase-store/termbase.effects";
import { SearchResultsEffects } from "./search-results/search-results.effects";
// import { MastiffOffersEffect } from "./mastiff-offers-store/mastiff-offers.effect";

export const appEffectsList = [
    SscKeysEffects,
    SpecsEffects,
    AllDeviceEffects,
    ConfirmDeviceEffects,
    DeviceWarrantyEffects,
    Device_DeviceIdEffects,
    TermbaseEffects,
    SyncStatusEffects,
    RegionsEffects,
    AlertsEffects,
    SearchResultsEffects
]
