import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingScreenInterceptor } from './services/loader/loading.interceptor';
import { CustomUrlSerializer } from './services/custom-url-serializer';
import { SharedModule } from './modules/shared-module/shared.module';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './store/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { appEffectsList } from './store/app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { syncAPIErrorInterceptor } from './interceptors/syncAPIError.interceptor';
import { WpdWaitingModule } from './modules/feature-module/wpd-waiting-module/wpd-waiting.module';
import { UrlSerializer } from '@angular/router';
import { ThemeLoaderService } from './services/theme-loader/theme-loader.service';  
// import { provideClientHydration } from '@angular/platform-browser';


@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app' }),
    FormsModule,
    AppRoutingModule,
    WpdWaitingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot(appEffectsList),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass:syncAPIErrorInterceptor,
      multi: true
    },
    {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingScreenInterceptor,
    multi: true,
  },
  {
    provide: UrlSerializer, useClass: CustomUrlSerializer
  },
  ThemeLoaderService,
  // provideClientHydration()
  // APM Integration Disabled
  // EnvService,
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: (envService: EnvService) => () => envService.init(),
  //   deps: [EnvService],
  //   multi: true
  // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
