
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { PageService } from './page.service';
import { SearchResultsResponse, VerifyContext, VerifyResponseData } from '../../store/search-results/search-results.model';
import { Store } from '@ngrx/store';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { SearchResultsState } from '../../store/search-results/search-results.reducer';
import { LoadSearchResultsFailure, LoadSearchResultsSuccess, SearchResultsPayload } from '../../store/search-results/search-results.actions';
import { getSearchResultsState } from '../../store/search-results/search-results.selectors';
import { sscKeysSelector } from '../ssc-store/config.selectors';
import { isEmpty, isEqual, cloneDeep } from 'lodash-es';
import { ConfigKeysModel } from '../ssc-store/sscKeys.reducer';
import { CommonService } from './common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnPnConflictModalComponent } from '../../components/error-scenario/sn-pn-conflict-modal/sn-pn-conflict-modal.component';
import { pageTermbaseSelector } from '../../store/termbase-store/termbase.selector';
import { TermbaseKeysModel } from '../../store/termbase-store/termbase.reducer';
import { RegisterApiResponseMessages, PAGE_NAMES } from '../../constants/app-constants';
import { GlobalDataLayerService } from './globalDataLayer.service';
import { Verify } from 'crypto';

@Injectable({
    providedIn: 'root'
})
export class SearchResultsService {

    pageConfig = this.pageService.config;
    customCC: string;
    customLC: string;
    allTranslations: TermbaseKeysModel = {};
    constructor(
        private http: HttpClient,
        private pageService: PageService,
        private store: Store,
        private modalService: NgbModal,
        private globalDataService: GlobalDataLayerService,
        private commonService: CommonService
    ) {
        this.getCommonLocalFromSSC(this.pageConfig);
        this.store.select(pageTermbaseSelector)
        .pipe(filter(res => !isEmpty(res)))
        .subscribe((res) => {
            this.allTranslations = res;
        })
    }

    fetchSearchResults(payload: SearchResultsPayload): Observable<SearchResultsResponse> {
        this.customCC = this.customCC || this.pageConfig.cc;
        this.customLC = this.customLC || this.pageConfig.lc;

        const pn = payload?.productNumber ? `&productNumber=${encodeURIComponent(payload?.productNumber)}` : '';
        const url = `${this.pageConfig?.domain}/wcc-services/searchresult/${this.customCC + '-' + this.customLC}?q=${encodeURIComponent(payload?.query)}&context=${payload?.context}${pn}`;

        return this.http.get<SearchResultsResponse>(this.commonService.sanitizeUntrustedData(url))
            .pipe(
                map((searchResults: SearchResultsResponse) => {
                    if (this.isSNPNConflict(searchResults)) {
                        searchResults.conflict = true;
                        this.updateGDL(payload?.query);
                    } else if (this.isVerifyOnly(searchResults) || this.isNonUniqueSN(searchResults)) {
                        searchResults.data.kaaSResponse = null;
                    } else if (this.isKaasOnly(searchResults)) {
                        searchResults.data.verifyResponse = null;
                    }
                    this.storeVerifyContext(searchResults?.data?.verifyResponse?.data);
                    this.store.dispatch(new LoadSearchResultsSuccess(payload, searchResults));
                    return searchResults;
                }),
                catchError((err) => {
                    this.store.dispatch(new LoadSearchResultsFailure(err));
                    return throwError(err);
                })
            );
    }

    storeVerifyContext(verifyData: VerifyResponseData) {
        //create a new key in sesstion storage and store the verify data
        if (typeof sessionStorage !== 'undefined') {
            const productNumber = verifyData?.altProductNumber || verifyData?.productNumber;
            const [ sku, regionCode ] = productNumber ? productNumber?.split('#') : [];
            const contextData : VerifyContext =  {
                seoName: verifyData?.SEOFriendlyName,
                seriesOid: verifyData?.productSeriesOID,
                modelOid: verifyData?.productNameOID,
                serialNumber: verifyData?.serialNumber,
                sku,
                regionCode,
            }
            sessionStorage.setItem('verifyContext', JSON.stringify(contextData));
            if (this.pageConfig.pageName !== PAGE_NAMES.PDPLANDING) {
                // this.globalDataService.updateGDL({ Product: { historicalWebSupportFlag: (verifyData?.historicalWebSupportFlag) }}, true);
                this.globalDataService.updateGDL({ Product: {
                    seriesOid: verifyData?.productSeriesOID ?? '',
                    bigSeriesOid: verifyData?.productBigSeriesOid ?? '',
                    productNumberOid: verifyData?.productNumberOid ?? '',
                    productName: verifyData?.productName ?? '',
                    productSeriesName: verifyData?.productSeriesName ?? '',
                    productNumber: verifyData?.productNumber ?? '',
                    productLineCode: verifyData?.productLineCode ?? '',
                    productAudience: verifyData?.productAudience ?? '',
                    platformId: verifyData?.productPlatform ?? '',
                    serialNumber: verifyData?.serialNumber ?? '',
                    historicalWebSupportFlag: (verifyData?.historicalWebSupportFlag)
                  }}, true);
            }
            verifyData?.historicalWebSupportFlag ? sessionStorage?.setItem('historicalProductData', JSON.stringify(verifyData)):delete sessionStorage.historicalProductData;
        }
    }

    getSearchResult(query: string, context: string, productNumber?: string): Observable<SearchResultsResponse> {
        //select data for searchresults from store and if data already available return the data else dispatch event to get data
        productNumber = productNumber || null;
        const searchResultsPayload: SearchResultsPayload = { query, context, productNumber };
        return this.store.select(getSearchResultsState)
            .pipe(
                first(),
                switchMap((state: SearchResultsState) => {
                    if (isEqual(state?.payload, searchResultsPayload)) {
                        return of(state?.searchResults);
                    } else {
                        return this.fetchSearchResults({ query, context, productNumber })
                    }
                })
            )
    }

    // serialNumberRequired true means serial number is required for search results and will return verifyResponse
    // serialNumberRequired false means serial number is not required for search results and will return kaaSResponse and verifyResponse both in case of conflict
    checkSnPnConflict(query, context, productNumber, serialNumberRequired = false): Promise<SearchResultsResponse> {
        return new Promise((resolve, reject) => {
            this.getSearchResult(query, context, productNumber)
                .pipe(first(data => !isEmpty(data)))
                .subscribe((resp: SearchResultsResponse) => {
                    const response = cloneDeep(resp);
                    // If Conflict then open the Conflict Modal
                    if (response.conflict) {
                        const modalRef = this.modalService.open(SnPnConflictModalComponent, { windowClass: 'conflict-modal-container', size: 'lg' });
                        modalRef.componentInstance.searchResults = response;
                        modalRef.componentInstance.serialNumberRequired = serialNumberRequired;
                        modalRef.componentInstance.allTranslations = this.allTranslations;
                        modalRef.componentInstance.searchResultsPayload = { query, context, productNumber };
                        modalRef.result.then((result: SearchResultsResponse) => {
                            if(result?.result?.verifySelected) {
                                this.storeVerifyContext(result?.result?.verifyData);
                            }
                            resolve(result);
                        }, () => {
                            resolve(null)
                        });
                    } else {
                        //If No Conflict return the Search Results
                        resolve(response);
                    }
                }, (error) => {
                    reject(error);
                });
        });
    }

    isSNPNConflict(searchResults: SearchResultsResponse): boolean {
        return (
            searchResults?.data?.kaaSResponse?.data &&
            searchResults?.data?.verifyResponse?.data &&
            searchResults?.data?.kaaSResponse?.code === 200 &&
            searchResults?.data?.verifyResponse?.code === 200
        );
    }

    isKaasOnly(searchResults: SearchResultsResponse): boolean {
        return (
            searchResults?.data?.kaaSResponse?.code === 200 &&
            searchResults?.data?.verifyResponse?.code !== 200
        );
    }

    isVerifyOnly(searchResults: SearchResultsResponse): boolean {
        return (
            searchResults?.data?.verifyResponse?.code &&
            searchResults?.data?.verifyResponse?.message &&
            searchResults?.data?.kaaSResponse?.code &&
            searchResults?.data?.kaaSResponse?.code !== 200
        );
    }

    isNonUniqueSN(searchResults: SearchResultsResponse): boolean {
        return (
            searchResults?.data?.verifyResponse?.code === 406 &&
            searchResults?.data?.verifyResponse?.message === RegisterApiResponseMessages.MULTIPLE_DEVICES
        );
    }

    updateGDL(query) {
        this.globalDataService.updateGDL({
            SNoverlap: query
        }, true);
    }

    getCommonLocalFromSSC(pageConfig) {
        if (pageConfig) {
            this.store.select(sscKeysSelector)
                .pipe(first(res => !isEmpty(res)))
                .subscribe((sscKeys: ConfigKeysModel) => {
                    if (sscKeys && sscKeys.COMMON_FALLBACK_COUNTRY_CODE && sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue) {
                        const localObj = this.commonService.getCustomLocale(this.commonService.safeParseJSON(sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue), pageConfig);
                        this.customCC = localObj['cc'];
                        this.customLC = localObj['lc'];
                    } else {
                        this.customCC = pageConfig.cc;
                        this.customLC = pageConfig.lc;
                    }
                });
        }
    }

}
