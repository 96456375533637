import { createReducer, on } from "@ngrx/store";
import { ClearMastiffOffers, GetMastiffOffers, StoreMastiffOffers, } from "./mastiff-offers.actions";
import { MastiffOffersPayload } from "./mastiff-offers.model";

export interface MastiffOffersState{
    loading: boolean;
    success: boolean;
    offers: OfferEntity[] | null;
    payload?: MastiffOffersPayload;
}

export interface MastiffOffersResponse {
  responseId: string;
  errorMessage: string;
  errorCode: string;
  code?: number;
  hasCapacity?: boolean;
  data?: (OfferEntity)[] | null;
}

export interface OfferEntity {
  buttonURL?: string | null;
  offerTitle: string;
  category: string;
  productSKU: string;
  offerRank: string;
  capacity?: string;
}


const initialState: MastiffOffersState  = {
    loading: false,
    success: false,
    offers: [],
    payload: null
}

export const MastiffOffersReducer = createReducer(
  initialState,
  on(GetMastiffOffers, (state: MastiffOffersState) => ({
      ...state,
      loading: true
  })),

  on(StoreMastiffOffers, (state: MastiffOffersState, action) => ({
            ...state,
            loading: false,
            offers: action.offers,
            payload: action.payload
  })),

  on(ClearMastiffOffers, (state: MastiffOffersState, action) => ({
      ...initialState
  })),
);
