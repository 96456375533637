import { Injectable, OnDestroy } from "@angular/core";
import { HttpService } from '../../../services/service_loader/http.service';
import { Observable, Observer, Subject } from 'rxjs';
import { PageService } from '../../../services/common_service/page.service';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { takeUntil, map, first } from 'rxjs/operators';
import { sortBy, filter, orderBy, isEmpty} from 'lodash-es'
import { SEOService } from '../../../services/seo-service/seo-service';
import { CommonService } from '../../../services/common_service/common.service';
import { GlobalDataLayerService } from '../../../services/common_service/globalDataLayer.service';
import * as moment from 'moment';
import { Store } from "@ngrx/store";
import { AppState } from "../../../store/app.reducer";
import { StartFetchingSscKeysAction } from "../../../services/ssc-store/sscKeys.actions";
import { ConfigKeysModel } from "../../../services/ssc-store/sscKeys.reducer";
import * as device_deviceIdAction from "../../../store/device-deviceId-store/device-deviceId.actions";
import { device_deviceIdSelector } from "../../../store/device-deviceId-store/device-deviceId.selectors";
import * as deviceWarrantyAction from "../../../store/device-warranty-store/device-warranty.actions";
import { DeviceWarrantySelector } from "../../../store/device-warranty-store/device-warranty.selectors";
import { cloneDeep } from "lodash-es";
import { AuthService } from "../../../services/common_service/auth.service";
import { TermbaseKeysModel } from "../../../store/termbase-store/termbase.reducer";
import { AppSelectorStore } from "../../../store/app.selector";
import { syncApiError } from "../../../constants/app-constants";
import { sscKeysSelector } from "../../../services/ssc-store/config.selectors";
import { VerifyContext } from "../../../store/search-results/search-results.model";

// Already Provided in component
@Injectable(
  {
    providedIn: 'root'
  }
)
export class DeviceCardService implements OnDestroy{
  pageConfig: any = this.pageService.config;
  private destroySubject$: Subject<void> = new Subject();
  userData;

  allTranslations:TermbaseKeysModel = {};
  warrantyTextcolorCode: string;
  warrantyTextHovercolorCode: string;

  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
    private pageService: PageService,
    private seoService: SEOService,
    private commonService: CommonService,
    private globalDataService: GlobalDataLayerService,
    private store: Store<AppState>,
    private authservice:AuthService,
    private appSelectorStore:AppSelectorStore
  ) { 
    this.authservice.userDataSub.subscribe(res=>{
      this.userData = res;
    })
    this.appSelectorStore.getAllTranslations$()
    .pipe(first(res=> !isEmpty(res)))
    .subscribe(res=>this.allTranslations = res);
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }

  getDashboardSSC() {

    this.store.dispatch(new StartFetchingSscKeysAction({
      pageName: 'dashboard'
    }));
    
    return new Observable((observer: Observer<any>) => {

      this.store.select(sscKeysSelector)
      .pipe(first(res=> !isEmpty(res)))
      .subscribe((sscKeys: ConfigKeysModel) => {
          const mastiffUrl = sscKeys.MASTIFF_SPOS_ENDPOINT_URL && sscKeys.MASTIFF_SPOS_ENDPOINT_URL.keyValue || null;
          const botSwitchEnabled = sscKeys.VA_CHAT_BOT_API_GLOBAL_ENABLED?.keyValue ?? null;
          const hpOneEnabled = sscKeys.HP_ONE_ENABLED?.keyValue ?? null;
          this.warrantyTextcolorCode = sscKeys.DASHBOARD_WARRANTY_STATUS_COLOR_CODE?.keyValue ?? null;
          this.warrantyTextHovercolorCode = sscKeys.DASHBOARD_WARRANTY_STATUS_LINK_HOVER_COLOR_CODE?.keyValue ?? null;
          observer.next({ success : true, mastiffUrl, botSwitchEnabled, hpOneEnabled});
      });
  
    });

  }

  getLoggedInState(authData) {
    let isUserLoggedIn;

    if (authData && authData.state && (authData.state === 'sessionExpired' || authData.state === 'sessionInvalid')) {
      isUserLoggedIn = false;
    } else {
      isUserLoggedIn = true;
    }

    return isUserLoggedIn;
  }

  updateMetaInfo(translations) {

    this.seoService.setTitle(translations['#pps_WCC_FS_Dashboard_Device_MetaTag_Title']);
    this.seoService.updateMetaTagIndex('noindex, nofollow');
  }

  getSliderData(params: any = {}): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const url = `/wcc-services/cms-v2/contexual/${this.pageConfig.cc}-${this.pageConfig.lc}/wcc_dashboard_spoc_carousel`;

      const sliderConfig = {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        }
      };

      let sliderData: any;

      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('skipLoadingScreen', 'true');

      let httpParams = new HttpParams();

      if (params) {
        for (const key in params) {
          httpParams = httpParams.set(key, params[key])
        }
      }

      this.http
        .get(url, { headers, params: httpParams })
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              if (res.data.length === 1 && res.data[0].errorCode) {
                if (res.data[0].errorCode === '204') {
                  sliderData = null;

                  observer.next({ success: true, sliderData, sliderConfig });
                }
              } else {
                sliderData = res.data;
                // Order banners based on the order parameter
                sliderData = sortBy(sliderData, [function (banner) { return banner.order; }]);

                if(this.pageConfig.pageName === 'dashboardCard'){
                  // Create list of banners to be shown on mobile
                  const mobileBanners = filter(sliderData, function(banner) { return (banner.displayContext === 'both' || banner.displayContext === 'mobile') });

                  // Create list of banners to be shown on web
                  const webBanners = filter(sliderData, function(banner) { return (banner.displayContext === 'both' || banner.displayContext === 'web') });

                  // Use the necessary banners
                  sliderData = this.pageConfig.isDesktop ? webBanners : mobileBanners;

                  // Show only 3 banners for device card only
                  sliderData = sliderData.slice(0,3);
                }  else if (this.pageConfig.pageName === 'topicPage') {
                  sliderData = sliderData.slice(0,5);
                }

                observer.next({ success: true, sliderData, sliderConfig });
              }

              observer.complete();
            }
          },
          err => {
            observer.next({ success: false });
            observer.complete();
          });
    })
  }
  getDeviceCardDetails(deviceId, formattedUTCOffset, payload) {
    let payloadTosend = {
      deviceId:deviceId,
      formattedUTCOffset:formattedUTCOffset,
      payloadForAPI:payload
    }
    this.store.dispatch(new device_deviceIdAction.Device_DeviceID(payloadTosend));
    return new Observable(observer =>{
      this.store.select(device_deviceIdSelector).subscribe(res=>{
        observer.next(res);
        if(res.dataLoaded){
          observer.complete();
        }
      })
    });
  }
  getDeviceCardDetailsnew(deviceId, formattedUTCOffset, payload){
    let httpHeaders: HttpHeaders; 
    if(!isEmpty(this.userData)){
        httpHeaders = new HttpHeaders().set(syncApiError.key,syncApiError.value);
    }else{
        httpHeaders = new HttpHeaders();
    }
    const url = `/wcc-services/profile/devices/${deviceId}?offset=${formattedUTCOffset}&isMobile=${this.pageConfig.isMobile}`;
    return this.http
      .post(url, payload, {headers:httpHeaders})
      .pipe(takeUntil(this.destroySubject$))
      .pipe(
        map((res: any) => {
          let isRetiredProduct = false; // this.isRetiredProduct
          let allTilesData; // this.allTilesData
          let matchHeightInput = ''; // this.matchHeightInput
          let deviceInfo; // this.deviceInfo
          let serviceUnavailable; // this.serviceUnavailable
          let sessionTimedOut // this.sessionTimedOut
          let modifiedNickname; // this.modifiedNickname

          // ALM defect to Update Contact Support link on Sec Nav
          if (res && res.data && res.data.metadata) {
            let metaData = res.data.metadata;

            if (metaData.historical === "yes") {
              let updatedUrl = `/${this.pageConfig.cc}-${this.pageConfig.lc}/contact`;
              this.commonService.contexualSecNavContactHpLink.next(updatedUrl);
            }
            else {
              const PN = metaData?.altProductNumber || metaData.productNumber;
              let updatedUrl = `/${this.pageConfig.cc}-${this.pageConfig.lc}/contact/product/${metaData.seriesSEOName}/${metaData.seriesoid}/model/${metaData.productNameoid}?sku=${PN}&serialnumber=${metaData.serialNumber}`;
              this.commonService.contexualSecNavContactHpLink.next(updatedUrl);
            }

            // Set verify context data in session storage for links to work
            const productNumber = metaData?.altProductNumber || metaData?.productNumber;
            const [ sku, regionCode ] = productNumber ? productNumber?.split('#') : [];
            const contextData : VerifyContext =  {
                seoName: metaData?.seriesSEOName,
                seriesOid: metaData?.seriesOid,
                modelOid: metaData?.productNameoid,
                serialNumber: metaData?.serialNumber,
                sku,
                regionCode,
            }
            sessionStorage.setItem('verifyContext', JSON.stringify(contextData));
          }

          let rowArray = [];

          if (res && res.data && res.data.metadata && res.data.metadata.historical) {
            if (res.data.metadata.historical === 'yes') {
              isRetiredProduct = true;
            }
            if (res.data.metadata.historical === 'no') {
              isRetiredProduct = false;
            }
          }

          if (res && res.data && res.data.tiles && res.data.tiles.length && res.code === 200) {
            serviceUnavailable = false;
            allTilesData = res.data.tiles;
            let totalRowSize = 0;
            allTilesData = orderBy(allTilesData, ['order'], ['asc']);

            allTilesData = allTilesData.map((tileData) => {
              totalRowSize = totalRowSize + (+tileData.size);
              let rowVal;

              if (this.pageConfig.isTablet) {
                rowVal = Math.floor(totalRowSize / 3) + (totalRowSize % 3 !== 0 ? 1 : 0);
              } else {
                rowVal = Math.floor(totalRowSize / 4) + (totalRowSize % 4 !== 0 ? 1 : 0);
              }

              rowArray.push(rowVal);
              tileData.rowClass = 'row' + rowVal;

              return tileData;
            });

            // Ironing out duplicate values from array
            rowArray = [...new Set(rowArray)];

            rowArray.forEach((value) => {
              matchHeightInput = matchHeightInput + `row${value},` + `row${value}header,` + `row${value}content, ` + `row${value}link,` + `row${value}container, `;
            });

            matchHeightInput = matchHeightInput.slice(0, -1);

            deviceInfo = res.data;

            const gdlUpdateObj = {
              // Template: this.pageConfig.templateName,
              Product: {
                productName: deviceInfo.metadata.productName,
                productLineCode: deviceInfo.metadata.productLine,
                productOID: deviceInfo.metadata.productNameoid
              },
              SPOSOffers: {
                'errorCode':''
              }
            }

            this.globalDataService.updateGDL(gdlUpdateObj);

            deviceInfo.metadata.nickName = modifiedNickname = deviceInfo.metadata.nickName ? deviceInfo.metadata.nickName : this.allTranslations['#pps_WCC_FS_Dashboard_Device_Default_Nickname'];

            sessionTimedOut = false;
            serviceUnavailable = false;

            return {
              code: 200,
              isRetiredProduct,
              allTilesData,
              matchHeightInput,
              deviceInfo,
              serviceUnavailable,
              sessionTimedOut,
              modifiedNickname
            };
          } else if ((res && res.code) && (res.code === 403)) {
            serviceUnavailable = false;
            sessionTimedOut = true;

            return {
              code: 403,
              serviceUnavailable,
              sessionTimedOut
            };
          } else if ((res && res.code) && (res.code === 406)) {
            sessionTimedOut = false;

            return {
              code: 406,
              status: 'error',
              sessionTimedOut
            };
          } else if (res && res.code && res.code === 500) {
            sessionTimedOut = false;

            return {
              code: 500,
              status: 'error',
              sessionTimedOut
            };
          } else {
            sessionTimedOut = false;

            return {
              status: 'error',
              sessionTimedOut
            };
          }
        }
      )
    );
  }

  getDeviceQuickActions(isRetiredProduct) {
    return this.http
      .get(`wcc-services/cms-v2/${this.pageConfig.cc}-${this.pageConfig.lc}/wcc_dashboard_quickactions`)
      .pipe(map(
        res => {
          if (res && res.data) {
            let deviceModifyOptions = res.data;
            const contactHPLLinkObj = filter(deviceModifyOptions, function (o) { return o.type === 'contactHP'; });
            const removeDeviceLinkObj = filter(deviceModifyOptions, function (o) { return o.type === 'removeDevice'; });
            const addDeviceLinkObj = filter(deviceModifyOptions, function (o) { return o.type === 'addDevice'; });

            if (isRetiredProduct) {
              deviceModifyOptions = filter(deviceModifyOptions, function (o) { return o.type != 'contactHP'; });
            }

            return {
              deviceModifyOptions,
              contactHPLLinkObj,
              removeDeviceLinkObj,
              addDeviceLinkObj
            }
          }
        }
      ));
  }

  getDashboardBanner() {
    return this.http
      .get(`wcc-services/cms-v2/${this.pageConfig.cc}-${this.pageConfig.lc}/wcc_hpone_dashboard_banner`)
      .pipe(map(
        res => {
          if (res && res?.data?.length) {
            return res.data[0];
          } else {
            return null
          }
        }
      ));
  }

  updateNickName(deviceId, sanitizedNickName, payload) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('skipLoadingScreen', 'true');

    const url = this.commonService.getSanitizedUrl(`/wcc-services/profile/devices/${deviceId}`);

    return this.httpClient.patch(url, payload, { headers })
      .pipe(map(
        (res: any) => {
          let serviceUnavailable;
          let sessionTimedOut;

          if (res && res.code && res.code === 200 && res.data) {
            const parsedData = JSON.parse(res.data);

            if (parsedData.FaultItemList && parsedData.FaultItemList.length !== 0) {
              return {
                status: 'error'
              }
            } else {
              const modifiedNickname = decodeURIComponent(sanitizedNickName);

              return {
                code: 200,
                modifiedNickname,
                editModeView: false
              }
            }
          } else if ((res && res['code']) && (res['code'] === 403)) {
            serviceUnavailable = false;
            sessionTimedOut = true;

            return {
              code: 403,
              serviceUnavailable,
              sessionTimedOut
            };
          } else if ((res && res['code']) && (res['code'] === 406 || res['code'] === 500)) {
            return {
              code: 406
            };
          } else {
            return {
              status: 'error'
            };
          }
        })
      )

  }

  getUrlForContactHp(data, deviceInfo) {
    let replacedDirectUrl;

    if (data.type === 'contactHP') {
      const replaceObj = {
        '{cc}-{lc}': this.pageConfig.locale,
        '{seriesSEO}': deviceInfo.metadata.seriesSEOName,
        '{seriesoid}': deviceInfo.metadata.seriesoid|| deviceInfo.metadata.seriesOid,
        '{modeloid}': deviceInfo.metadata.productNameoid,
        '{sku}':  deviceInfo.metadata.altProductNumber?.split('#')?.shift() || deviceInfo.metadata.productNumber?.split('#')?.shift(),
        '{serialno}': deviceInfo.metadata.serialNumber
      };
      // tslint:disable-next-line:max-line-length
      replacedDirectUrl = data.link.replace(/{cc}-{lc}|{seriesSEO}|{seriesoid}|{modeloid}|{sku}|{serialno}/gi, function (matched) {
        return replaceObj[matched];
      });
    } else {
      replacedDirectUrl = `${this.pageConfig.locale}${data.link}`;
    }

    if (replacedDirectUrl.includes('contact/product')) {
      replacedDirectUrl = this.commonService.addParamsToUrl(replacedDirectUrl, {});
    }

    return replacedDirectUrl;
  }

  getDeviceWarrantyStatus(warrantyData: any) {
    const unknownState = (warrantyData.details.statusCode === 4) ? true : false;
    let exceptionWarranty = false;
    let expired;
    let showCircleGraph;
    let updatedWarrantyPercentage;
    let outerStrokeColor;
    let underWarranty;
    let oneMonthRemaining;
    let pct;
    let warrantyTextcolorCode;
    let warrantyTextHovercolorCode;

    if (warrantyData.details.statusCode >= 6) {
      exceptionWarranty = true;
     // expired = true;
    }
    if(warrantyData.details.statusCode === 5){ 
      expired = true;
    }

    warrantyTextcolorCode  = JSON.parse(this.warrantyTextcolorCode)[warrantyData?.details?.statusCode] ?? JSON.parse(this.warrantyTextcolorCode)["default"];
    warrantyTextHovercolorCode = JSON.parse(this.warrantyTextHovercolorCode)[warrantyData?.details?.statusCode] ?? JSON.parse(this.warrantyTextHovercolorCode)["default"];


    if (warrantyData && warrantyData.details.startDate && warrantyData.details.endDate && warrantyData.details.lastCheck && warrantyData.details.statusCode !== 4) {
      showCircleGraph = true;
    } else {
      showCircleGraph = false;
    }

    if (warrantyData) {
      if (warrantyData.details.startDate !== '' && warrantyData.details.startDate != null) {
        const warrantyStartDate = moment(warrantyData.details.startDate);
        const warrantyEndDate = moment(warrantyData.details.endDate);
        const lastCheckDate = moment(warrantyData.details.lastCheck);
        const warrantyDateDiff = warrantyEndDate.diff(warrantyStartDate, 'days');

        // Lastchaeck- start date
        const warrantyPeriodTillLCD = lastCheckDate.diff(warrantyStartDate, 'days');
        // End date -(Lastcheck- start date)
        const warrantyRemainingDays = warrantyDateDiff - warrantyPeriodTillLCD;
        const circleRadius = 32;
        const circleArea = Math.PI * (circleRadius * 2);
        // const circle: any = document.getElementById('bar');

        if (warrantyRemainingDays < 0) {
          updatedWarrantyPercentage = 0;
        } else {
          updatedWarrantyPercentage = ((warrantyRemainingDays / warrantyDateDiff) * 100);
        }

        if (warrantyRemainingDays >= 93) {
          outerStrokeColor = '#4882c2';
          underWarranty = true;
          oneMonthRemaining = false;
          expired = false;
        } else if (warrantyRemainingDays < 93 && updatedWarrantyPercentage > 0) {
          outerStrokeColor = '#f0a83b';
          oneMonthRemaining = true;
          underWarranty = false;
          expired = false;
        } else if (warrantyRemainingDays <= 0) {
          outerStrokeColor = '#bf2026';
          updatedWarrantyPercentage = 100;
          expired = true;
          oneMonthRemaining = false;
          underWarranty = false;
        }

        pct = ((100 - updatedWarrantyPercentage) / 100) * circleArea;
      }

      return {
        unknownState,
        exceptionWarranty,
        expired,
        showCircleGraph,
        updatedWarrantyPercentage,
        outerStrokeColor,
        underWarranty,
        oneMonthRemaining,
        pct,
        warrantyTextcolorCode,
        warrantyTextHovercolorCode
      };
    }
  }

  getWarrantyLastCheckDifference(warrantyData: any) {
    const currentTime = moment().utc();

    if (warrantyData) {
      const lastCheck = moment(warrantyData.details.lastCheck);
      const lastcheckDiff = currentTime.diff(lastCheck, 'h');
      return lastcheckDiff;
    }
  }
  checkWarrantyStatus(refresh, deviceId, offset, warrantyWidgetLoadingScreen = true){
    let payload={
      refresh:refresh,
      deviceId:deviceId,
      offset:offset,
      warrantyWidgetLoadingScreen:warrantyWidgetLoadingScreen
    }
    this.store.dispatch(new deviceWarrantyAction.LoadDeviceWarranty(payload));
    return new Observable(observer =>{
      this.store.select(DeviceWarrantySelector).subscribe(res=>{
        observer.next(res);
        if(res.dataLoaded){
          observer.complete();
        }
      })
    });
  }
  checkWarrantyStatusNew(refresh, deviceId, offset, warrantyWidgetLoadingScreen = true) {
    let headers: HttpHeaders;
    if(!isEmpty(this.userData)){
      headers = new HttpHeaders().set(syncApiError.key,syncApiError.value);
    }else{
      headers = new HttpHeaders();
    }
    if (warrantyWidgetLoadingScreen) {
      headers = headers.append('skipLoadingScreen', 'true');
    }

    return this.http
      .get(`/wcc-services/profile/devices/warranty/${deviceId}?cc=${this.pageConfig.cc}&offset=${offset}&refresh=${refresh}&isMobile=${this.pageConfig.isMobile}`, { headers })
      .pipe(
        map(data => {
          let res  = cloneDeep(data);
          if (res && res.data) {
            const warrantyData: any = {};

            warrantyData.endDate = res?.data?.endDate;
            warrantyData.startDate = res?.data?.startDate;
            warrantyData.lastCheck = res?.data?.lastCheck;
            warrantyData.caption = res?.data?.caption;
            warrantyData.imgWarranty = res?.data?.imgWarranty;
            warrantyData.status = res?.data?.status;
            warrantyData.code = res?.code;

            return {
              data: warrantyData
            }
          } else if ((res && res.code) && (res.code === 403 || res.code === 406 || res.code === 500)) {
            return {
              code: res.code
            }
          } else {
            return {
              status: 'error'
            }
          }
        })
      );
  }
}
