import { Component, OnInit, Renderer2 } from '@angular/core';
import { PageService } from './services/common_service/page.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  pageConfig = this.pageService.config;
  title = 'hp-wcc';

  constructor(
    private pageService: PageService
  ) {}

  async ngOnInit() {
    if (this.pageConfig.isBrowser && typeof document !== 'undefined') {
      if (document.referrer.includes('/contact-hp/product')) {
        sessionStorage.removeItem('productData');
      }
    }
  }
}
