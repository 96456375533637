import { Injectable } from '@angular/core';
import DOMPurify from 'dompurify';
import { PageService } from './page.service';

/*
    Docs: https://www.npmjs.com/package/dompurify
    https://cure53.de/purify
    Use this project to check the what content is allowed and what is not,
    in ``DomPurify.sanitize()``
*/
@Injectable({
    providedIn: 'root'
})
export class DomPurifierService{

    private pageConfig = this.pageService.config;

    constructor(
        private pageService: PageService
    ) { }


    sanitize(data:string, config){
        if(this.pageConfig.isBrowser){
            return DOMPurify.sanitize(data, config)?.toString();
        }else{
            return data;
        }
    }

    sanitizeAllow_Target(data:string){
        return this.sanitize(data, { ADD_ATTR: ['target'] });
    }

    sanitizeAllow_Iframe_Target(data:string){
        return this.sanitize(data, { ADD_TAGS: ["iframe"], ADD_ATTR: ['target']});
    }

}
