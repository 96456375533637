
//import { EnvService } from './env.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { ServiceUnavailableErrorComponent } from './components/error-scenario/service-unavailable/service-unavailable.component';


function getRouterOptions() {
  const options: ExtraOptions = { enableTracing: false, scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' };

  if(typeof window != 'undefined' && window.location?.href?.includes("/service/")) {
    //for service page do not reset the scroll position when clicking on the tabs
    delete options.scrollPositionRestoration;
  }

  return options;
}

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: ':lang/printer',
        loadChildren: () => import('./pages/printer-landing/printer-landing.module').then(m => m.PrinterLandingModule)
      },
      {
        path: ':lang/computer',
        loadChildren: () => import('./pages/computer-landing/computer-landing.module').then(m => m.ComputerLandingModule),
      },
      {
        path: ':lang/printer-setup',
        loadChildren: () => import('./pages/printer-setup/printer-setup.module').then(m => m.PrinterSetupModule)
      },
      {
        path: ':lang/dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: ':lang/add-device',
        loadChildren: () => import('./pages/add-device/add-device.module').then(m => m.AddDeviceModule),
      },
      {
        path: ':lang/retired-products',
        loadChildren: () => import('./pages/retired-products-landing/retired-products-landing.module').then(m => m.RetiredProductsModule),
      },
      {
        path: ':lang/error-page/:errorPage',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      },
      {
        path: ':lang/deviceSearch',
        loadChildren: () => import('./pages/device-search/device-search.module').then(m => m.DeviceSearchModule),
      },
      {
        path: ':lang/dashboard/device',
        loadChildren: () => import('./pages/dashboard/device-card/device-card.module').then(m => m.DeviceCardModule),
      },
      {
        path: ':lang/search',
        loadChildren: () => import('./pages/search-results/search-results.module').then(m => m.SearchResultsModule),
      },
      {
        path: ':lang/contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
      },
      // {
      //   path: ':lang/topic/:topic_seo_name',
      //   loadChildren: () => import('./pages/solution-template-topic/solution-template.module').then(m => m.SolutionTemplateModule)
      // },
      // {
      //   path: ':lang/topic',
      //   loadChildren: () => import('./pages/solution-topic-landing/solution-topic-landing.module').then(m => m.SolutionTopicLandingModule),
      // },
      {
        path: ':lang/remoteconnection',
        loadChildren: () => import('./pages/agent-remote/agent-remote.module').then(m => m.AgentRemoteModule),
      },
      {
        path: ':lang/products/:level1',
        loadChildren: () => import('./pages/product-landing/product-landing.module').then(m => m.ProductLandingModule),
        data: {
          templateName: 'ProductCategoryL0'
        }
      },
      {
        path: ':lang/products/:level1/:level2',
        loadChildren: () => import('./pages/product-landing/product-landing.module').then(m => m.ProductLandingModule),
        data: {
          templateName: 'ProductCategoryL1'
        }
      },
      {
        path: ':lang/products/:level1/:level2/:level3',
        loadChildren: () => import('./pages/product-landing/product-landing.module').then(m => m.ProductLandingModule),
        data: {
          templateName: 'ProductCategoryL2'
        }
      },
      {
        path: ':lang/products',
        loadChildren: () => import('./pages/product-landing/product-landing.module').then(m => m.ProductLandingModule),
        data: {
          templateName: 'ProductCategoryLanding'
        }
      },
      {
        path: ':lang/service/:serviceName/:seriesId',
        loadChildren: () => import('./pages/sdp/sdp.module').then(m => m.SDPPageModule),
      },
      {
        path: ':lang/conversion',
        loadChildren: () => import('./pages/conversion/conversions.module').then(m => m.ConversionsModule),
      },
      {
        path: ':lang/security-bulletins',
        loadChildren: () => import('./pages/security-bulletin/security-bulletin.module').then(m => m.SecurityBulletinModule),
      },
      {
        path: ':lang/security-bulletin-archive',
        loadChildren: () => import('./pages/security-bulletin/security-bulletin.module').then(m => m.SecurityBulletinModule),
      },
      {
        path: ':lang/security-bulletin-rss',
        loadChildren: () => import('./pages/security-bulletin-rss/security-bulletin-rss.module').then(m => m.SecurityBulletinRssModule),
      },
      {
        path: ':lang/check-warranty',
        loadChildren: () => import('./pages/check-warranty/check-warranty.module').then(m => m.CheckWarrantyModule),
      },
      {
        path: ':lang/warrantyresult/:productSlug/:seriesOID/model/:productId',
        loadChildren: () => import('./pages/check-warranty/check-warranty.module').then(m => m.CheckWarrantyModule),
      },
      {
        path: ':lang/dispute-warranty',
        loadChildren: () => import('./pages/dispute-warranty/dispute-warranty.module').then(m => m.DisputeWarrantyModule),
      },
      {
        path: ':lang/product/detect',
        loadChildren: () => import('./pages/wpd/wpd.module').then(m => m.WpdModule)
      },
      {
        path: ':lang/product',
        loadChildren: () => import('./pages/pdp/pdp.module').then(m => m.PdpModule)
      },
      {
        path: ':lang/drivers',
        loadChildren: () => import('./pages/swd/swd.module').then(m => m.SwdModule)
      },
     
      {
        path: ':lang/poly',
        loadChildren: () => import('./pages/poly/poly.module').then(m => m.PolyModule)
      },
      {
        path: ':lang',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: ':lang/sync-error',
        loadChildren: () => import('./pages/error/sync-api-error-module/sync-api-error.module').then(m => m.SyncAPIErrorModule),
      },
      {
        path: ':lang/polyconversion',
        loadChildren: () => import('./pages/poly-conversion/poly-conversion.module').then(m => m.PolyConversionModule),
      },
      {
        path: ':lang/service-unavailable',
        component: ServiceUnavailableErrorComponent,
        data: { specs: true }
      },
      {
        path: ':lang/document/:documentId',
        loadChildren: () => import('./pages/document-template/document-template.module').then(m => m.DocumentTemplateModule)
      },
      {
        /**
         * A sample page to view all variation of visid design lib components
         * This page will be blocked by devops for production
         */
        path: ':lang/wcc-assets/internal/design-components',
        loadChildren: () => import('./components/design-variable-components/visid-design-lib/visid-design-lib.routing').then(m => m.designVariableRoutes),
        data: {
          pageName: 'siteHome'
        }
      },
      {
        path: '', redirectTo: 'us-en', pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [
    ApmModule,
    RouterModule.forRoot(routes, getRouterOptions())
  ],
  exports: [RouterModule],
  providers: [ApmService]
})

export class AppRoutingModule {

  // APM Integration Disabled
  // constructor(service: ApmService,private envservice: EnvService) {
  //   envservice.setEnvVariables();
  //   // Get the environment name from the EnvService
  //   const serviceName = envservice.apmUIServerName;
  //   const serverUrl = envservice.apmServerUrl;
  //   const environment = envservice.env;
  //   // Initialize the APM service with the environment name
  //   const apm = service.init({
  //     serviceName: serviceName,
  //     serverUrl : serverUrl,
  //     environment : environment
  //   });
  //   // console.log("🚀 ~ file: app-routing.module.ts:159 ~ AppRoutingModule ~ constructor ~ apm:", apm);
  // }

 }





