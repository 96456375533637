import { Injectable, OnDestroy } from '@angular/core';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty, cloneDeep, includes } from "lodash-es";
import { PageService } from '../../../services/common_service/page.service';
import { ALERT_TYPE_ORDER } from '../../../constants/app-constants';
import { AppState } from '../../../store/app.reducer';
import { StartFetchingAlertsAction } from '../../../store/alerts-store/alerts.actions';
import { ProductAttributesModel } from '../../../pages/pdp/store/product-attributes/product-attributes.reducer';
import { FetchProductAttributesAction } from '../../../pages/pdp/store/product-attributes/product-attributes.actions';
import { WarrantySpecsData, Device, ProductSpecs } from '../../../model/warranty-spec.model';
import { CommonService } from '../../../services/common_service/common.service';
import { WarrantySpecsStoreSelector } from '../../../store/specs-store/specs.selectors';

@Injectable({ providedIn: 'root' })
export class HPNotificationIconsService implements OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  // need this flag as we need to separate productAlerts and globalAlerts
  separateGlobalAlerts = false;
  fetchAlerts = false;

  productAttributesFetched = false;
  productSpecFetched = false;
  productSpec: ProductSpecs;
  dashboardProductData: any = {};
  spdProductData: any = {};

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private pageService: PageService,
    private route: Router,
    private store: Store<AppState>,
    private commonService: CommonService
  ) {}

  setFocusOnModal(documentId) {
    if (this.pageConfig.isBrowser) {
      setTimeout(() => {
        document.getElementById(documentId)?.focus();
      }, 500)
    }
  }

  fetchNotifications() {
    const payload: any = {
      'allAlerts': false,
      'cclc': `${this.pageConfig.cc}-${this.pageConfig.lc}`,
    }
    this.separateGlobalAlerts = false;
    this.fetchAlerts = false;
    this.productAttributesFetched = false;
    this.productSpecFetched = false;
    const routeParams = this.pageConfig.routeParams;
    if (this.pageConfig.pageName === 'pdpLanding') {
      this.separateGlobalAlerts = true;
      this.getProductAttributes(routeParams?.seriesOid, routeParams?.modelOid);
    } else if (this.pageConfig.pageName === 'swdDrivers') {
      this.separateGlobalAlerts = true;
      this.getProductAttributes(routeParams?.seriesOID, routeParams?.modelOID);
    } else if (this.route.url.includes('/printer-setup')) {
      this.getProductAttributes(routeParams?.productOid, routeParams?.modelOid);
    } else if(this.pageConfig.pageName === 'sdpPage'){
      this.productSpecFetched = true; //SSVSPRT-15260--To fix this added this line
    } else if (this.route.url.includes('/service/')) {
      // In this case API call is triggered within sdp.component.ts
    } else if (this.route.url.includes('/warrantyresult/')) {
      this.getProductAttributes(routeParams?.seriesOID, routeParams?.productId);
    } else if (this.route.url.includes('/contact/')) {
      if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('contactFormMetaData')) {
        const sessionData = sessionStorage.getItem('contactFormMetaData') && JSON.parse(sessionStorage.getItem('contactFormMetaData'));
        const productContext = sessionData?.contactProductContext;
        this.getProductAttributes(productContext?.seriesOid, productContext?.modelOid);
      } else {
        this.dispatchAlertsEvent(payload);
      }
    } else if (this.route.url.includes('/dashboard/device')) {
      // In this case API call is triggered within device-card.component.ts
    } else {
      this.dispatchAlertsEvent(payload);
    }
  }

  getAlertsForDashboard(productData) {
    this.productSpecFetched = true; // This is set to true, as we dont have spec call in this case
    this.dashboardProductData = productData;
    this.getProductAttributes(productData?.seriesoid, productData?.productNameoid);
  }

  getAlertsForSdpPage(productData: ProductSpecs) {
    this.productSpecFetched = true; // This is set to true, as we dont have spec call in this case
    this.spdProductData = productData;
    this.getProductAttributes(productData?.productSeriesOid, productData?.productNameOid);
  }

  getProductAttributes(seriesOid, modelOid) {
    const payload: any = {
      'allAlerts': true,
      'cclc': `${this.pageConfig.cc}-${this.pageConfig.lc}`,
      'oid': `${seriesOid},globalalert`
    };
    this.fetchAlerts = true;
    this.store.dispatch(new FetchProductAttributesAction({seriesOid, modelOid}));
    this.getProductAttributes$()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((productAttributes: ProductAttributesModel) => {
        payload['productAttributes'] = productAttributes.mergedAttributes;
        this.productAttributesFetched = true;
        if (this.productSpecFetched && this.productAttributesFetched) {
          this.dispatchAlertsEvent(payload);
        }
      });

      // getting spec data from store
      this.store.select(WarrantySpecsStoreSelector)
      .pipe(
        first(res => res.dataLoaded && !res.loading)
        )
        .subscribe((res) => {
          if (res?.warrantySpecsData?.error === null) {
            if (!isEmpty(res.warrantySpecsData?.data?.devices[0]?.productSpecs)) {
              const contactProdRibbonData: WarrantySpecsData = cloneDeep(res.warrantySpecsData);
              const device = contactProdRibbonData?.data?.devices[0] || {} as Device;
              this.productSpec = device?.productSpecs;
              payload['standardHierarchySet'] = this.productSpec.standardHierarchySet;
              payload['oid'] = `${this.productSpec.productSeriesOid},globalalert`;
              this.productSpecFetched = true;
              if (this.productSpecFetched && this.productAttributesFetched) {
                this.dispatchAlertsEvent(payload);
              }
          }
        }
      });
  }

  dispatchAlertsEvent(payload, fetchDataAgain = false) {
    if (fetchDataAgain) {
      this.fetchAlerts = true;
    }
    this.store.select((appState: AppState) => appState.alerts.dataFetchSuccess).pipe(first()).subscribe(alertsAvailable => {
      if(this.fetchAlerts || !alertsAvailable) {
        this.fetchAlerts = false;
        this.store.dispatch(new StartFetchingAlertsAction(payload));
      }
    })
  }

  sortAndFilterAlerts(alertData, productAlerts = false) {
    const alertTypeOrder = ALERT_TYPE_ORDER;
    let formattedAlertData = [];
    formattedAlertData = alertData.filter((alert) => alert.alertDesc !== '')
    formattedAlertData = formattedAlertData.sort((a, b) => {
      const aTypeIndex = alertTypeOrder.indexOf(a.alertType);
      const bTypeIndex = alertTypeOrder.indexOf(b.alertType);
      return aTypeIndex - bTypeIndex;
    })

    formattedAlertData = formattedAlertData.map((alertData) => {
      const type = alertData.alertType === 'Negative' ? 'Critical' : 'Informational';
      const title = alertData.alertType === 'Negative' ? '#pps_WCC_FS_AlertNotification_Alert' : '#pps_WCC_FS_AlertNotification_Information';
      let alertCTALink = alertData.alertCTALink;
      if (this.route.url.includes('/service/')) {
        alertCTALink = alertCTALink.includes('{ProductContext}') ? alertCTALink.replace(/\/{ProductContext}/g, this.addProductContextFromSpdPage(alertCTALink)) : alertCTALink;
      } else if (this.route.url.includes('/dashboard/device')) {
        alertCTALink = alertCTALink.includes('{ProductContext}') ? alertCTALink.replace(/\/{ProductContext}/g, this.addProductContextFormDashboard(alertCTALink)) : alertCTALink;
      } else {
        alertCTALink = this.commonService.addProductContextToURL(alertData.alertCTALink, this.productSpec);
      }
      return { ...alertData, title, type, alertCTALink };
    });

    if (productAlerts)  {
      formattedAlertData = formattedAlertData.filter((alert) => alert.alertCategory === 'Product-Alert' || alert.alertCategory === '' );
    } else if(this.separateGlobalAlerts) {
      formattedAlertData = formattedAlertData.filter((alert) => alert.alertCategory === 'Global-Alert');
    }

    return formattedAlertData;
  }

  getProductAttributes$(): Observable<ProductAttributesModel>{
    const ob$: Observable<ProductAttributesModel> = this.store.select<ProductAttributesModel>((appState: AppState)=>appState.productAttributes.data)
      .pipe(
          filter((productAttributes:ProductAttributesModel)=>productAttributes && !isEmpty(productAttributes))
      );
    return ob$;
  }

  addProductContextFromSpdPage(link) {
    if(!this.spdProductData?.seriesContext) {
      if(includes(link, 'drivers')){
        return `${this.spdProductData?.productSeriesSEOName}/model/${this.spdProductData?.productNameOid}`;
      } else {
        return `${this.spdProductData?.productSeriesSEOName}/${this.spdProductData?.productSeriesOid}/model/${this.spdProductData?.productNameOid}`;
      }
    } else  {
      return `${this.spdProductData?.productSeriesSEOName}/${this.spdProductData?.productSeriesOid}`;
    }
  }

  addProductContextFormDashboard(link) {
    if(includes(link, 'drivers')){
      return `${this.dashboardProductData?.seriesSEOName}/model/${this.dashboardProductData?.productNameoid}`;
    } else {
      return `${this.dashboardProductData?.seriesSEOName}/${this.dashboardProductData?.seriesoid}/model/${this.dashboardProductData?.productNameoid}`;
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
