import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Theme } from './theme';


@Injectable({
  providedIn: 'root'
})
export class ThemeLoaderService {
  private style: HTMLLinkElement;
  private cssFile: string;
  private themeCSSID: string = 'themeCSS';
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  setTheme(theme: string, renderer2: Renderer2) {
    this.cssFile = `wcc-assets/${theme}.css`;
    this.removeExistingThemeStyle(theme, renderer2, this.themeCSSID);
    
    // Create a link element via Angular's renderer to avoid SSR troubles
    this.style = renderer2.createElement('link') as HTMLLinkElement;

    // Set type of the link item and path to the css file
    renderer2.setProperty(this.style, 'rel', 'stylesheet');
    renderer2.setProperty(this.style, 'href', this.cssFile);
    renderer2.setProperty(this.style, 'id', this.themeCSSID);

    // Add the style to the head section
    renderer2.appendChild(this.document.head, this.style);

    //add respective class to body tag
    if(Theme[theme.toUpperCase()]) {
      this.document.body.classList.add(Theme[theme.toUpperCase()]);
    }
  }

  removeExistingThemeStyle(theme, renderer2: Renderer2, themeCSSID: string) {
    // remove existing class from body
    Object.keys(Theme).forEach((key) => { 
      if(this.document.body.classList.contains(Theme[key]))  
        this.document.body.classList.remove(Theme[key]);
    });
    const themeIDHTMlElem = this.document.getElementById(themeCSSID);
    if (themeIDHTMlElem) {
      renderer2.removeChild(this.document.head, themeIDHTMlElem);
    }
  }
}